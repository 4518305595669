import { rem, Stack } from "@mantine/core";
import { CompanyOverview } from "./CompanyOverview";
import { CitationOverview } from "./CitationOverview";
import { useEffect, useState } from "react";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { CitationIndex } from "../../sources/models/Citation";

interface SideDrawerProps {
  chatId: string;
}

export default function SideDrawer({ chatId }: SideDrawerProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [sidebarCompanyId, , clearSidebarCompanyId] = useLocalStorage({
    key: LocalStorageKeys.SidebarCompanyId,
  });
  const [sidebarCitation, , clearSidebarCitation] = useLocalStorage({
    key: LocalStorageKeys.SidebarCitation,
  });
  const [companyId, setCompanyId] = useState<number>();
  const [citation, setCitation] = useState<CitationIndex>();

  useEffect(() => {
    // When chat is changed, clear only citation to preserve company overview
    clearSidebarCitation();

    return () => {
      // When component is unmounted, clear both company and citation
      clearSidebarCompanyId();
      clearSidebarCitation();
    };
  }, [chatId, clearSidebarCitation, clearSidebarCompanyId]);

  useEffect(() => {
    if (!sidebarCompanyId) {
      setCompanyId(undefined);
      return;
    }
    if (!parseInt(sidebarCompanyId)) return;
    if (sidebarCitation) clearSidebarCitation(); // Clear the citation if opening company
    setCompanyId(parseInt(sidebarCompanyId));
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarCompanyId]);

  useEffect(() => {
    if (!sidebarCitation) {
      setCitation(undefined);
      return;
    }
    if (sidebarCompanyId) clearSidebarCompanyId(); // Clear the company if opening citation
    setCitation(JSON.parse(sidebarCitation));
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarCitation]);

  return (
    <>
      {opened && (
        <Stack
          w={citation?.sourceType === "Estimates" ? rem(500) : rem(400)}
          p={20}
          bg="secondary"
          style={{
            borderLeft: "1px solid var(--mantine-color-secondary-hover-5)",
          }}
        >
          {companyId && <CompanyOverview companyId={companyId} close={close} />}
          {citation && <CitationOverview citation={citation} close={close} />}
        </Stack>
      )}
    </>
  );
}
