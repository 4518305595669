import { useGetSource } from "../../../sources/api/useGetSource";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { Group, Stack, ScrollArea, Text, Box, rem } from "@mantine/core";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarCompanyDocumentLink from "./SidebarCompanyDocumentLink";
import classes from "../../styles/SidebarCitations.module.css";

interface SidebarDocumentCitationProps {
  citation: CitationIndex;
}

export function SidebarDocumentCitation({
  citation,
}: SidebarDocumentCitationProps) {
  const { source, getSource, isLoading } = useGetSource();
  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getSource(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <Group>
        {source && (
          <SidebarCompanyDocumentLink
            document={source}
            chunkId={parseIntOrUndefined(memoizedCitation.chunkId)}
          />
        )}
      </Group>
      <Stack h="100%">
        {source && (
          <>
            <ScrollArea.Autosize
              scrollbars="y"
              offsetScrollbars
              scrollbarSize={4}
            >
              <Text className={classes["chunk-text"]}>{source.content}</Text>
            </ScrollArea.Autosize>
          </>
        )}
      </Stack>
      <Box py={rem(4)}></Box>
    </>
  );
}
