import { useLocalStorage } from "@mantine/hooks";
import { LocalStorageKeys } from "../../../shared/enums/LocalStorageKeys";
import { CitationIndex } from "../../sources/models/Citation";
import { useMemo } from "react";
import { ActionIcon, Center, Group, Stack } from "@mantine/core";
import classes from "../../../features/sources/styles/CitationHoverCard.module.css";
import { IconX } from "@tabler/icons-react";
import { SidebarDocumentCitation } from "./sources-sidebar/SidebarDocumentCitation";
import { CitationSourceType } from "../../../shared/enums/CitationSourceType";
import { SidebarNewsCitation } from "./sources-sidebar/SidebarNewsCitation";
import { SidebarEstimateCitation } from "./sources-sidebar/SidebarEstimateCitation";

interface CitationOverviewProps {
  citation: CitationIndex;
  close: () => void;
}

export function CitationOverview({ citation, close }: CitationOverviewProps) {
  const [, , clearSidebarCitation] = useLocalStorage<CitationIndex>({
    key: LocalStorageKeys.SidebarCitation,
  });
  const memoizedCitation = useMemo(() => citation, [citation]);

  const handleCloseClick = () => {
    clearSidebarCitation();
    close();
  };

  function selectCitationComponent() {
    switch (memoizedCitation.sourceType) {
      case CitationSourceType.News:
        return <SidebarNewsCitation citation={memoizedCitation} />;
      case CitationSourceType.Filing:
      case CitationSourceType.Transcript:
        return <SidebarDocumentCitation citation={memoizedCitation} />;
      case CitationSourceType.Estimates:
        return <SidebarEstimateCitation citation={memoizedCitation} />;
    }
  }

  return (
    <Stack h="97%">
      <Group justify="space-between">
        <Center className={`${classes["center"]} ${classes["text"]}`}>
          {memoizedCitation.index + 1}
        </Center>
        <ActionIcon onClick={handleCloseClick} variant="simple">
          <IconX />
        </ActionIcon>
      </Group>
      {selectCitationComponent()}
    </Stack>
  );
}
